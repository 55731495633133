import React from "react";
import { Container, Row, Col } from "reactstrap";
import MediaQuery from "react-responsive";

import TLCCLogo from "images/tlcc_logo.png";
import CELogo from "images/ce-logo.svg";
import CDELogo from "images/cde_logo.png";

import "./pageHeader.styles.scss";
import { getConfigs } from "appConfig";

const PageHeader = () => {
  const { tlccOfficialSite } = getConfigs();
  return (
    <header className="page-header">
      <div className="color-stripe">
        <div className="stripe-1" />
        <div className="stripe-2" />
        <div className="stripe-3" />
        <div className="stripe-4" />
      </div>
      <Container>
        <Row>
          <Col>
            <div className="logos">
              <div className="primary-logo">
                <a
                  href={tlccOfficialSite || "http://www.tlccsurvey.org"}
                  target="_blank"
                  rel="noopener noreferrer"
                  title="TLCC Survey Main Site"
                >
                  <img
                    src={TLCCLogo}
                    alt="TLCC Logo"
                    className="main-project-logo"
                  />
                </a>
              </div>
              <MediaQuery minWidth={768}>
                <div className="secondary-logos">
                  <a
                    href="http://www.camb-ed.com/americas"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Cambridge Education Survey Services"
                  >
                    <img src={CELogo} alt="CE Logo" className="ce-logo" />
                  </a>
                  <a
                    href="https://www.cde.state.co.us"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Colorado Department of Education"
                  >
                    <img src={CDELogo} alt="CDE Logo" className="client-logo" />
                  </a>
                </div>
              </MediaQuery>
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default PageHeader;
