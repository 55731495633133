import { createSelector } from "reselect";

const getOrganizationsState = (state) => state.organizations;
const getAllOrganizations = (state) => state.organizations.organizations;
const getRootOrganizationId = (state) => state.organizations.rootOrganizationId;
const getFilterKeyword = (state) => state.organizations.filterKeyword;
const getOrganizationsRequestStatus = createSelector(
  getOrganizationsState,
  ({ loading, loaded, error }) => ({ loaded, loading, error })
);

const orgSortFunc = ({ name: aName }, { name: bName }) => {
  if (aName > bName) return 1;
  if (aName < bName) return -1;
  return 0;
};

const getOrgList = (
  { id, type, name, completed, total, report_id, children_ids = [] },
  allOrgs
) => {
  let list = { id, type, name, completed, total, report_id };

  if (children_ids.length > 0) {
    list.sub_organizations = children_ids
      .map((child_id) => getOrgList(allOrgs[child_id] || {}, allOrgs))
      .sort(orgSortFunc);
  }

  return list;
};

const getRootOrganization = createSelector(
  [getAllOrganizations, getRootOrganizationId],
  (allOrganizations, rootOrgId) => allOrganizations[rootOrgId]
);

const getOrganizationTree = createSelector(
  [getAllOrganizations, getRootOrganization],
  (allOrganizations, rootOrg) => getOrgList(rootOrg, allOrganizations)
);

const filterOrgs = (organizations, keyword) =>
  organizations.reduce((filertedOrgs, org) => {
    const filteredSubOrgs = filterOrgs(org.sub_organizations || [], keyword);

    if (org.name.match(new RegExp(keyword, "ig"))) {
      return [...filertedOrgs, org];
    } else if (filteredSubOrgs.length > 0) {
      return [
        ...filertedOrgs,
        {
          ...org,
          sub_organizations: filteredSubOrgs,
        },
      ];
    }

    return filertedOrgs;
  }, []);

const getFilteredOrganizationTree = createSelector(
  [getOrganizationTree, getFilterKeyword],
  (orgTree, filterKeyword) => {
    if (!filterKeyword || filterKeyword.length === 0) {
      return orgTree;
    } else {
      return {
        ...orgTree,
        sub_organizations: filterOrgs(orgTree.sub_organizations, filterKeyword),
      };
    }
  }
);

const getSearchableOrganizations = createSelector(
  [getAllOrganizations],
  (allOrganizations) =>
    Object.values(allOrganizations)
      .map(({ id, name }) => ({ name, id }))
      .sort(orgSortFunc)
);

export {
  getAllOrganizations,
  getOrganizationTree,
  getSearchableOrganizations,
  getFilterKeyword,
  getFilteredOrganizationTree,
  getOrganizationsRequestStatus,
};
