import {
  LOAD_REPORT_FAILED,
  LOAD_REPORT_STARTED,
  LOAD_REPORT_SUCCESSFUL,
  SET_CURRENT_REPORT_ID,
  SET_CURRENT_REPORT_SCOPE_INDEX,
  LOAD_PDF_REPORT_DATA_STARTED,
  LOAD_PDF_REPORT_DATA_SUCCESSFUL,
  LOAD_PDF_REPORT_DATA_FAILED,
  LOAD_REPORT_DISAGGREGATION_METADATA_STARTED,
  LOAD_REPORT_DISAGGREGATION_METADATA_SUCCESSFUL,
  LOAD_REPORT_DISAGGREGATION_METADATA_FAILED,
  LOAD_HISTORIC_ITEM_WORDINGS_METADATA_STARTED,
  LOAD_HISTORIC_ITEM_WORDINGS_METADATA_SUCCESSFUL,
  LOAD_HISTORIC_ITEM_WORDINGS_METADATA_FAILED,
} from "constants/reportConstants.js";

const initialState = () => ({
  reports: {},
  pdfReports: {},
  metadata: {
    historicItemWordings: {
      itemWordings: {},
      request: {
        loaded: false,
        loading: false,
        failedReason: null,
      },
    },
    disaggregationDetails: {
      detailsData: [],
      request: {
        loaded: false,
        loading: false,
        failedReason: null,
      },
    },
  },
  currentReportId: null,
});

export default function reportReducer(state = initialState(), action = {}) {
  switch (action.type) {
    case SET_CURRENT_REPORT_ID:
      return {
        ...state,
        currentReportId: action.payload.reportId,
      };
    case LOAD_HISTORIC_ITEM_WORDINGS_METADATA_STARTED:
      return {
        ...state,
        metadata: {
          ...state.metadata,
          historicItemWordings: {
            itemWordings: {},
            request: {
              loaded: false,
              loading: true,
              failedReason: null,
            },
          },
        },
      };
    case LOAD_HISTORIC_ITEM_WORDINGS_METADATA_SUCCESSFUL:
      return {
        ...state,
        metadata: {
          ...state.metadata,
          historicItemWordings: {
            itemWordings: action.payload,
            request: {
              loaded: true,
              loading: false,
              failedReason: null,
            },
          },
        },
      };
    case LOAD_HISTORIC_ITEM_WORDINGS_METADATA_FAILED:
      return {
        ...state,
        metadata: {
          ...state.metadata,
          historicItemWordings: {
            itemWordings: {},
            request: {
              loaded: false,
              loading: false,
              failedReason: action.error,
            },
          },
        },
      };
    case LOAD_REPORT_DISAGGREGATION_METADATA_STARTED:
      return {
        ...state,
        metadata: {
          ...state.metadata,
          disaggregationDetails: {
            detailsData: [],
            request: {
              loaded: false,
              loading: true,
              failedReason: null,
            },
          },
        },
      };
    case LOAD_REPORT_DISAGGREGATION_METADATA_SUCCESSFUL:
      return {
        ...state,
        metadata: {
          ...state.metadata,
          disaggregationDetails: {
            detailsData: action.payload,
            request: {
              loaded: true,
              loading: false,
              failedReason: null,
            },
          },
        },
      };
    case LOAD_REPORT_DISAGGREGATION_METADATA_FAILED:
      return {
        ...state,
        metadata: {
          ...state.metadata,
          disaggregationDetails: {
            detailsData: [],
            request: {
              loaded: false,
              loading: false,
              failedReason: action.error,
            },
          },
        },
      };
    case LOAD_REPORT_STARTED:
      return {
        ...state,
        reports: {
          ...state.reports,
          [action.payload.reportId]: {
            loaded: false,
            loading: true,
            failedReason: null,
          },
        },
      };
    case LOAD_REPORT_SUCCESSFUL:
      return {
        ...state,
        reports: {
          ...state.reports,
          [action.payload.reportId]: {
            id: action.payload.reportId,
            loaded: true,
            loading: false,
            currentReportScopeIndex: 0,
            data: action.payload.data,
            failedReason: null,
          },
        },
      };
    case LOAD_REPORT_FAILED:
      return {
        ...state,
        reports: {
          ...state.reports,
          [action.payload.reportId]: {
            ...state.reports[action.payload.reportId],
            id: action.payload.reportId,
            loaded: false,
            loading: false,
            failedReason: action.payload.failedReason,
          },
        },
      };
    case SET_CURRENT_REPORT_SCOPE_INDEX:
      return {
        ...state,
        reports: {
          ...state.reports,
          [action.payload.reportId]: {
            ...state.reports[action.payload.reportId],
            currentReportScopeIndex: action.payload.index,
          },
        },
      };
    case LOAD_PDF_REPORT_DATA_STARTED:
      return {
        ...state,
        pdfReports: {
          ...state.pdfReports,
          [action.payload.reportId]: {
            loaded: false,
            loading: true,
            failedReason: null,
          },
        },
      };
    case LOAD_PDF_REPORT_DATA_SUCCESSFUL:
      return {
        ...state,
        pdfReports: {
          ...state.pdfReports,
          [action.payload.reportId]: {
            id: action.payload.reportId,
            loaded: true,
            loading: false,
            data: action.payload.data,
            failedReason: null,
          },
        },
      };
    case LOAD_PDF_REPORT_DATA_FAILED:
      return {
        ...state,
        pdfReports: {
          ...state.pdfReports,
          [action.payload.reportId]: {
            ...state.pdfReports[action.payload.reportId],
            id: action.payload.reportId,
            loaded: false,
            loading: false,
            failedReason: action.payload.failedReason,
          },
        },
      };
    default:
      return state;
  }
}
