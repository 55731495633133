import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "./store/configStore";

import { unregister } from "./registerServiceWorker";

import "./styles/index.scss";
import AppContainer from "containers/AppContainer/AppContainer";
import { getConfigs, initConfigs } from "appConfig";

initConfigs()
  .then((configs) => {
    if (configs?.debugMode) {
      console.log("App Initialized", configs);
    }
  })
  .finally(() => {
    const configs = getConfigs();
    render(
      <Provider store={store}>
        <BrowserRouter basename={configs.baseRoute ?? "/"}>
          <AppContainer />
        </BrowserRouter>
      </Provider>,
      document.getElementById("root")
    );
  });

// registerServiceWorker();
unregister();
