import { apiBaseUrl, reportYear } from "../appConfig";

const fetchReportData = async (reportID) => {
  const response = await fetch(
    `${apiBaseUrl}/data/${reportYear}/reports/${reportID}.json`
  );
  const report = await response.json();
  return report;
};

const fetchReportDisaggregationDetails = async () => {
  const response = await fetch(
    `${apiBaseUrl}/data/${reportYear}/report-metadata/disaggregation-details.json`
  );
  const metaData = response.json();
  return metaData;
};

const fetchHistoricalItemWordings = async () => {
  const response = await fetch(
    `${apiBaseUrl}/data/${reportYear}/report-metadata/historic-item-wording.json`
  );
  const metaData = response.json();
  return metaData;
};

const fetchPDFReportData = async (reportID) => {
  const response = await fetch(
    `${apiBaseUrl}/data/${reportYear}/pdfData/${reportID}.json`
  );
  const pdfData = response.json();
  return pdfData;
};

export {
  fetchReportData,
  fetchPDFReportData,
  fetchReportDisaggregationDetails,
  fetchHistoricalItemWordings,
};
