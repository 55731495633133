export const minResponseThreshold = 5;
export const minResponseRateThreshold = 0.5;
export const minDistrictResponseThreshold = 10;
export const minDistrictResponseRateThreshold = 0.4;

export const DISTRICT_TYPE = "district";

export const reportYear = 2020;
export const apiBaseUrl = "/api";
export const rootOrganizationId = 401722;

let configs = {
  debugMode: false,
  dataRequestFormUrl: null,
  supportFormUrl: null,
  feedbackFormUrl: null,
  summaryReportUrl: null,
  tlccOfficialSite: null,
  tlcc2018ReportUrl: null,
  supportEmail: null,
  baseRoute: "/",
};

export const initConfigs = () =>
  fetch(`/configs.json`)
    .then((res) => res.json())
    .then((remoteConfigs) => {
      Object.assign(configs, remoteConfigs || {});
      return configs;
    })
    .catch((e) => console.error(`Unable to fetch app configurations:`, e));

export const getConfigs = () => ({
  ...configs,
});
