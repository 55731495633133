import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getOrganizationsRequestStatus } from "selectors/organizationSelectors";
import { loadOrganizations as loadOrganizationsAction } from "actions/organizationActions";

import App from "components/App/App";

const AppContainer = () => {
  const { loading, loaded, error } = useSelector(getOrganizationsRequestStatus);
  const dispatch = useDispatch();
  const loadOrganizations = useCallback(
    () => dispatch(loadOrganizationsAction()),
    [dispatch]
  );

  useEffect(() => {
    if (!loading && !loaded && !error) {
      loadOrganizations();
    }
  }, [loading, loaded, error, loadOrganizations]);

  return (
    <App
      loading={loading}
      loaded={loaded}
      error={error}
      loadOrganizations={loadOrganizations}
    />
  );
};

export default AppContainer;
