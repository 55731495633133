import { call, put, takeLatest } from "redux-saga/effects";
import {
  LOAD_REPORT_DISAGGREGATION_METADATA_STARTED,
  LOAD_HISTORIC_ITEM_WORDINGS_METADATA_STARTED,
} from "../constants/reportConstants";
import {
  loadReportDisaggregationDetailsFailed,
  loadReportDisaggregationDetailsSuccess,
  loadHistoricItemWordingSuccess,
  loadHistoricItemWordingFailed,
} from "../actions/reportActions";

import {
  fetchReportDisaggregationDetails,
  fetchHistoricalItemWordings,
} from "../api/reportAPI";

function* fetchReportDisaggregationDetailsSaga() {
  try {
    const metaData = yield call(fetchReportDisaggregationDetails);
    yield put(loadReportDisaggregationDetailsSuccess(metaData));
  } catch (e) {
    yield put(loadReportDisaggregationDetailsFailed(e.message));
  }
}

function* fetchHistoricalItemWordingsSaga() {
  try {
    const metaData = yield call(fetchHistoricalItemWordings);
    yield put(loadHistoricItemWordingSuccess(metaData));
  } catch (e) {
    yield put(loadHistoricItemWordingFailed(e.message));
  }
}

function* reportMetaDataSaga() {
  yield takeLatest(
    LOAD_REPORT_DISAGGREGATION_METADATA_STARTED,
    fetchReportDisaggregationDetailsSaga
  );
  yield takeLatest(
    LOAD_HISTORIC_ITEM_WORDINGS_METADATA_STARTED,
    fetchHistoricalItemWordingsSaga
  );
}

export default reportMetaDataSaga;
